<template>
  <div :class="$style.content">
    <!-- 选择歌词风格 -->
    <ul ref="stylelist" :class="[
  $style['style-list'],
  { [$style.active]: changeAnimate === true }
]">
      <li v-for="(item, index) in items" :class="$style['style-item']" :key="index"
        @click="chooseStyle(index, item.id, item.name)" :style="{ backgroundImage: `url(${item.icon})` }">
        <p>
          <span> {{ item.name }}</span>
          <img :src="require('@/assets/images/line.png')" alt="" />
        </p>
      </li>
    </ul>
    <div v-if="loadMore" :class="$style.change" @click="handleChange">
      {{ $t('changeBatch') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Styles',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    loadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      changeAnimate: false,
      bgs: [
        require('@/assets/images/gf.png'),
        require('@/assets/images/my.png'),
        require('@/assets/images/yg.png'),
        require('@/assets/images/xc.png'),
        require('@/assets/images/lx.png')
      ]
    }
  },
  watch: {
    items() {
      this.changeAnimate = true
      console.log(this.items);
    }
  },
  mounted() {
    this.$refs.stylelist.addEventListener('animationend', () => {
      this.changeAnimate = false
    })
  },
  methods: {
    chooseStyle(index, val, name) {
      this.$emit('on-click', { index: index, val: val, name: name })
    },
    handleChange() {
      this.$emit('on-change')
    }
  }
}
</script>

<style lang="scss" module>
.content {
  text-align: center;
  vertical-align: middle;
  position: relative;
  height: 330px;
}

.style-list {
  width: 1200px;
  height: 186px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.style-item {
  display: inline-block;
  text-align: center;
  background: no-repeat center / 100% 100%;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column-reverse;
  cursor: pointer;
  margin-right: calc((100% - 840px) / 5);
  margin-bottom: 70px;
  &:nth-child(6n) {
    margin-right: 0;
  }
  &:hover {
    background-image: url('~@/assets/images/hover.png');
  }

  &.active {
    background-image: url('~@/assets/images/hover.png');
  }

  p {
    font-size: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(44px);
    color: #3a577d;
    font-weight: 600;
    margin-top: 10px;

    img {
      margin-top: 10px;
      width: 54px;
      height: 2px;
    }
  }
}
</style>
